import React from "react"
import classnames from "classnames"

import styles from './step-title.module.css'

type Props = {
  step?: number
  children?: React.ReactNode
  className?: string
}

const StepTitle: React.FC<Props> = ({ step, children, className }) => {
  const titleClass = classnames(styles.stepTitle, className)
  return (
    <h3 className={titleClass}>
      <span className={styles.stepText}>step</span>
      <span className={styles.stepNumber}>{step}</span>
      {children}
    </h3>
  )
}

export default StepTitle
