import React from "react"
import classnames from "classnames"

import StepTitle from "./step-title"
import LinkButton from "./link-button"
import styles from "./step.module.css"

const Step3: React.FC = () => {
  return (
    <section id="step3" className={styles.stepSection}>
      <StepTitle step={3}>受講システムの接続テストを行う</StepTitle>
      <p className={classnames(styles.description, styles.testText)}>
        ご使用のパソコンでオンライン授業に参加できるか、事前に受講システムの接続テストができます。
      </p>
      <LinkButton
        className={styles.testButton}
        linkTo="https://zoom.us/test"
        text="Zoomの接続テストはこちら"
      />
      <p className={styles.supplement}>
        ※こちらをクリックするとZoomの接続テストページにジャンプします。
        <br />
        ※初回接続時にのみ、Zoomアプリケーションのインストールが必要です。
      </p>
    </section>
  )
}

export default Step3
