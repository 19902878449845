import React from "react"
import classnames from "classnames"

import { Image } from "../../common"
import styles from "./image-box.module.css"

type Props = {
  img: string
  title: string
  text?: string
  alt?: string
  className?: string
}

const ImageBox: React.FC<Props> = ({ img, title, text, alt, className }) => {
  const classNames = classnames(styles.imageBox, className)
  return (
    <div className={classNames}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{text}</p>
      <div className={styles.imageWrapper}>
        <Image filename={img} alt={alt} />
      </div>
    </div>
  )
}

export default ImageBox
