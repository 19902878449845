import React from "react"
import { Link } from "gatsby"

import { Layout } from "../components"
import { SEO, Image } from "../components/common"
import {
  GuideButton,
  SubTitle,
  Step1,
  Step2,
  Step3,
  HelpBox,
  AnswerBox,
  ReccomendTable,
} from "../components/online-guide"

import styles from "./online-guide.module.css"

const OnlineGuide: React.FC = () => {
  return (
    <Layout>
      <SEO title="Culture House オンライン受講ガイド" />
      <div className="container">
        <Image
          className={styles.onlineGuideLabel}
          filename="online-guide-label.jpg"
        />
        <div className={styles.guideButtonContainer}>
          <GuideButton order={1} to="/online-guide#setup">
            オンライン講座での受講に向けた３つのステップ
          </GuideButton>
          <GuideButton order={2} to="/online-guide#help">
            接続ができない場合のヘルプ
          </GuideButton>
          <GuideButton order={3} to="/online-guide#reccomend">
            推奨環境のご案内
          </GuideButton>
        </div>
        <section id="setup" className={styles.firstSection}>
          <SubTitle step={1}>
            オンライン講座での受講に向けた３つのステップ
          </SubTitle>
          <p className={styles.description}>
            どこからでも学べるオンライン講座の受講方法をご案内します。
          </p>
          <Step1 />
          <Step2 />
          <Step3 />
        </section>
        <section id="help" className={styles.secondSection}>
          <SubTitle step={2}>接続ができない場合のヘルプ</SubTitle>
          <div className={styles.helpBoxContainer}>
            <HelpBox
              className={styles.helpBox}
              title="オンラインクラスへの接続ができない"
            >
              <AnswerBox
                className={styles.answerBox}
                answer="お使いのPCはシステム要件を満たしていますか？"
              >
                推奨システムの確認は
                <Link className={styles.link} to="/online-guide#reccomend">
                  こちら
                </Link>
              </AnswerBox>
              <AnswerBox
                className={styles.answerBox}
                answer="アプリケーションのインストールはお済みですか？"
              >
                アプリケーションインストールがまだの方は
                <Link className={styles.link} to="/online-guide#step2">
                  こちら
                </Link>
              </AnswerBox>
              <AnswerBox
                className={styles.answerBox}
                answer="インターネット接続は適切に行われていますか？"
              >
                インターネットの接続状況の確認は
                <Link className={styles.link} to="/online-guide#step3">
                  こちら
                </Link>
              </AnswerBox>
            </HelpBox>
            <HelpBox
              className={styles.helpBox}
              title="音声が聞こえない／マイクで発言ができない"
            >
              <AnswerBox
                className={styles.answerBox}
                answer="オーディオ（音声）は接続されていますか？"
              >
                画面左下に「オーディオに参加」ボタンは表示されていませんか？
                <br />
                表示されている場合はボタンを押して音声に接続することで解消する可能性があります。
              </AnswerBox>
              <AnswerBox
                className={styles.answerBox}
                answer="パソコン自体の音声はONになっていますか？"
              >
                Windowsの場合は、右下のスピーカーマークをご確認ください。
                <br />
                Macbookなどをお使いの場合は、右上のスピーカーアイコンをご確認ください。
              </AnswerBox>
              <AnswerBox
                className={styles.answerBox}
                answer="イヤホンやマイクは正しく接続されていますか？"
              >
                接続状況を確認いただき、ご使用の機材に不具合がないことをご確認ください。
              </AnswerBox>
              <AnswerBox
                className={styles.answerBox}
                answer="マイクはミュートになっていませんか？"
              >
                左下にマイクアイコンが表示されている場合は、マイクがONになっていることをご確認ください。
              </AnswerBox>
            </HelpBox>
            <HelpBox
              className={styles.helpBox}
              title="Webカメラの映像が映らない"
            >
              <AnswerBox
                className={styles.answerBox}
                answer="Webカメラは正しく接続されていますか？"
              >
                まずはビデオマークの右側の^マークから「ビデオ設定」をご確認ください。内蔵カメラの場合は、不具合の可能性があります。またUSBで接続されるタイプの場合は、別のUSBポートに接続し直しをお試しください。
              </AnswerBox>
              <AnswerBox
                className={styles.answerBox}
                answer="ビデオボタンはONになっていますか？"
              >
                左下のビデオアイコンがONになっていることを確認ください。斜線が表示されている場合はOFFとなっております。
              </AnswerBox>
            </HelpBox>
          </div>
        </section>
        <section id="reccomend" className={styles.thirdSection}>
          <SubTitle step={3}>推奨環境のご案内</SubTitle>
          <div className={styles.reccomendContainer}>
            <p className={styles.reccomendText}>
              オンラインクラスでは、受講システムとしてZoomを利用します。
              <br />
              受講にあたっては、パソコン、Webカメラ、ヘッドセットまたはマイク付きイヤホン、インターネット環境を事前にご用意ください。
            </p>
            <ReccomendTable className={styles.reccomendTable} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default OnlineGuide
