import React from "react"
import classnames from "classnames"

import styles from "./answer-box.module.css"

type Props = {
  answer: string
  children?: React.ReactNode
  className?: string
}

const AnswerBox: React.FC<Props> = ({ answer, children, className }) => {
  return (
    <dl className={classnames(className)}>
      <dt className={styles.answer}>{answer}</dt>
      <dd className={styles.contents}>
        <span className={styles.triangle}>▶</span>
        {children}
      </dd>
    </dl>
  )
}

export default AnswerBox
