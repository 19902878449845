import React from "react"

import StepTitle from "./step-title"
import styles from "./step.module.css"

const Step1: React.FC = () => {
  return (
    <section id="step1" className={styles.stepSection}>
      <StepTitle step={1}>オンラインでの受講に必要なものを準備する</StepTitle>
      <div className={styles.preparations}>
        <p>
          パソコンと
          <br />
          ネット環境
        </p>
        <p>
          ヘッドセットまたは
          <br />
          マイク付きイヤホン
        </p>
        <p>
          Webカメラ
          <br />
          ※パソコン内蔵も可
        </p>
      </div>
    </section>
  )
}

export default Step1
