import React from "react"
import classnames from "classnames"
import { Link } from 'gatsby'

import styles from './guide-button.module.css'

type Props = {
  className?: string
  children?: React.ReactNode
  order?: number
  to: string 
}

const GuideButton: React.FC<Props> = (props) => {
  const { className, children, order, to } = props
  const buttonClass = classnames(styles.guideButton, className)

  return (
    <Link to={to} className={buttonClass}>
      <span className={styles.order}>{ order }</span>
      { children }
    </Link>
  )
}

export default GuideButton
