import React from "react"
import classnames from "classnames"

import styles from "./reccomend-table.module.css"

type Props = {
  className?: string
}

const ReccomendTable: React.FC<Props> = ({ className }) => {
  return (
    <dl className={classnames(styles.reccomendTable, className)}>
      <dt>インターネット環境</dt>
      <dd>
        有線LAN または
        無線（3Gまたは4G/LTE）
      </dd>
      <dt>パソコン</dt>
      <dd>
        MacOS 10.7以降を搭載のMac OS X
        <br />
        Windows 10、Windows 8または8.1、Windows 7
        <br />
        Win 8.1を搭載したSurface PRO 2、Win 10を搭載したSurface PRO
        3、iOSとAndroidデバイス
      </dd>
      <dt>Webカメラ</dt>
      <dd>内蔵カメラまたはUSBプラグインカメラ</dd>
      <dt>音声機器</dt>
      <dd>ヘッドセットまたはマイク付きイヤホン</dd>
    </dl>
  )
}

export default ReccomendTable
