import React from "react"
import classnames from "classnames"

import styles from "./help-box.module.css"

type Props = {
  title: string
  className?: string
  children?: React.ReactNode
}

const HelpBox: React.FC<Props> = ({ title, children, className }) => {
  return (
    <div className={classnames(styles.helpBox, className)}>
      <h4 className={styles.helpTitle}>{title}</h4>
      <div className={styles.helpContents}>{children}</div>
    </div>
  )
}

export default HelpBox
