import React from "react"
import classnames from "classnames"

import StepTitle from "./step-title"
import LinkButton from "./link-button"
import ImageBox from "./image-box"
import styles from "./step.module.css"

const Step2: React.FC = () => {
  return (
    <section id="step2" className={classnames(styles.stepSection, styles.step2)}>
      <div className={styles.background}></div>
      <StepTitle step={2}>受講システムをセットアップする</StepTitle>
      <p className={styles.smallStep}>
        <span className={styles.stepNum}>1</span>
        受講システム（Zoom）のセットアップページにアクセスする
      </p>
      <p className={styles.description}>
        オンライン講座を受講するには、受講システム（Zoom）のアプリケーションをインストールする必要があります。
        <br />
        以下のページにアクセスしてアプリケーションをインストールしてください。
      </p>
      <LinkButton
        className={styles.setupButton}
        linkTo="https://zoom.us/download#client_4meeting"
        text="セットアップページはこちら"
      />
      <p className={styles.supplement}>
        ※オンライン講座ではアプリケーションをインストールいただいた上での接続をお願いしております。
        <br />
        ※アプリケーションは最新版をインストールしてください。
      </p>
      <p className={styles.smallStep}>
        <span className={styles.stepNum}>2</span>
        受講システムのインストールを進める
      </p>
      <p className={styles.description}>
        「ダウンロードボタン」を押して、Zoomクライアントをダウンロードしてください。
      </p>
      <div className={styles.imageBoxContainer}>
        <ImageBox
          className={styles.imageBox}
          title="WebサイトからZoomをダウンロードする"
          img="zoom-1.jpg"
          alt="zoom 1"
        />
        <div className={styles.triangle}></div>
        <ImageBox
          className={styles.imageBox}
          title="アプリケーションのインストールを実行する"
          text={`ダウンロードが完了したら、ダウンロードファイルをクリックしてインストールを開始してください。\n\n※アプリケーションは最新版をインストールしてください。`}
          img="zoom-2.jpg"
          alt="zoom 2"
        />
        <div className={styles.triangle}></div>
        <ImageBox
          className={styles.imageBox}
          title="インストール完了"
          text="下記の画面が表示されたらインストールが完了です。"
          img="zoom-3.jpg"
          alt="zoom 3"
        />
      </div>
    </section>
  )
}

export default Step2
