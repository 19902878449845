import React from "react"
import classnames from "classnames"

import styles from "./link-button.module.css"

type Props = {
  className?: string
  linkTo?: string
  text?: string
}

const LinkButton: React.FC<Props> = ({ className, linkTo, text }) => {
  return (
    <a
      href={linkTo}
      target="_blank"
      className={classnames(styles.linkButton, className)}
    >
      <p>{text}</p>
    </a>
  )
}

export default LinkButton
