import React from "react"
import classnames from "classnames"

import styles from "./sub-title.module.css"

type Props = {
  className?: string
  children?: React.ReactNode
  step?: number
}

const SubTitle: React.FC<Props> = ({ className, children, step }) => {
  const subTitleClass = classnames(styles.subTitle, className)

  return (
    <h2 className={subTitleClass}>
      <span className={styles.step}>{step}</span>
      {children}
    </h2>
  )
}

export default SubTitle
